@import '../home';

@import '~@mshops-web-components/newsletter-modal/styles/explorer/mobile';

@import '~@mshops-components-library/common/src/Components/Section/styles/mobile';
@import '~@mshops-components-library/slide/src/styles/explorer/mobile';
@import '~@mshops-components-library/slider/src/styles/original/mobile';
@import '~@mshops-components-library/tab-with-items/src/styles/base/mobile';
@import '~@mshops-components-library/card-action/src/styles/mobile';
@import '~@mshops-components-library/modal-add-to-cart/src/styles/iframe-modal.mobile';

@import '../../../../components/payment-data/mobile';
@import '../../../../components/badge-carousel/styles/mobile';
@import '../../../../components/site-shopping-info/explore/mobile';
@import '../../../../components/message/styles/mobile';
@import '../../../../components/message/styles/mobile';

@import '../../../../appearance/buyerInfo/styles/mobile';

// Contact Page
@import '../../../../components/contact-form/styles/mobile';
@import '../../../../components/contact-info/styles/mobile';

// How Buy Landing
@import '../../../../components/HowBuyInfo/styles/mobile';

// Cancel Buy Landing
@import '../../../../appearance/cancelBuyInfo/styles/mobile';

// Bank Conditions Landing
@import '../../../../appearance/bankConditionsInfo/styles/mobile';

// LGPD Landing
@import '../../../../appearance/dataPrivacyInfo/styles/mobile';

// Legal Notices Landing
@import '../../../../components/LegalNoticesInfo/styles/mobile';

// Adhesion contracts Landing
@import '../../../../appearance/adhesionContractInfo/styles/mobile';

// Not Found Page for CCP
@import '../../../../components/NotFoundCCP/styles/mobile';

// Protected Purchase Landing
@import '../../../../components/ProtectedPurchaseInfo/styles/mobile';

// Splinter mobile
@import '~@mshops-components-library/splinter-grid/styles/explorer/mobile';

.home {
  padding: rem($small-home-padding);

  &.home--explorer {
    padding-top: rem($small-header-height-material);

    &.home--with-flash {
      padding-top: rem($small-header-height-material + $flash-component-height);
    }
  }
}

/* Modifiers for flash component and banner components */
.nav-header__banner {
  background-color: $light-gray;
  display: block;
  line-height: 0;
  padding-top: rem($small-header-height);
  width: 100%;
}

.nav-header__banner-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  height: rem(96px);
  width: 100%;
}

.header-wrapper--with-flash ~ .nav-header__banner {
  padding-top: rem($small-header-height + $flash-component-height);
}

.home--with-flash {
  padding-top: rem($small-header-height + $flash-component-height);
}

.home--with-banner {
  padding-top: 0;
}

.recommendations > .container {
  margin-bottom: 0;
}

@media (width >= 768px) {
  .home--with-flash {
    padding-top: rem($large-header-height + $flash-component-height);
  }
}

.recommendations .section-header {
  width: 100%;

  h1,
  h2,
  .section-header__title--base {
    color: $andes-black;
    display: block;
    font-size: rem($font-size-32);
    text-align: center;
    width: 100%;
  }

  a {
    margin: 0 auto !important;
  }
}

.slick-slide .ui-item__content {
  min-height: rem(115px);
  position: relative;

  .ui-item__price-block {
    bottom: 0;
    position: absolute;
  }

  .ui-item__title {
    position: absolute;
    top: 0;
  }
}

.slider .slide__text button {
  background-color: $andes-white;
  border: none !important;

  span {
    color: $andes-black !important;
  }
}

.section-text__subtitle {
  font-size: rem($font-size-16);
}

.section-text__title {
  font-size: rem($font-size-18);
}

.section-text__text {
  font-size: rem($font-size-14);
}

.section-text__row-buttons-actions__btn-action-user {
  font-size: rem($font-size-12);
}

.grid-container .with-discount__wrapper .ui-item__price {
  margin-top: 0 !important;
}

.grid-container .with-discount .ui-item__price-block {
  display: flex;
  flex-direction: column-reverse;
}

.carousel-with-tabs-mobile__list .ui-item__wrapper .ui-item__price-block {
  display: flex;
  flex-direction: column-reverse;
}

.ui-item__discount-box--hidden .ui-item__discount-text,
.ui-item__material-discount-text {
  font-size: rem(10px);
  font-weight: normal;
}

.home.home--explorer
  .mshops-recommendations-wrapper
  .carousel-with-tabs
  .carousel-with-tabs__tabs-list {
  box-shadow: 0 6px 16px 0 #0000001a;
}
